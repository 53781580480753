import { writable } from 'svelte/store';
import * as Sentry from '@sentry/browser';
import { _ } from 'svelte-i18n'

export function updateSession(session, data) {
  session.update(session => {
    Object.assign(session, data);
    return session;
  });
}

export async function preloadData(fetch, endpoints) {
  const responses = await Promise.all(endpoints.map(endpoint => fetch(`/api/v1/${endpoint}`)));
  // TODO: ensure successful response.
  return Promise.all(responses.map(async response => {
    if (!response.ok) throw new Error('server error');
    return response.json();
  }));
}

export function getRegion(countries, countryCode) {
  const country = countries.find(({ code }) => code === countryCode);
  return country && country.region.code;
}

export function getCountryCode({ detectedCountryCode, selectedCountryCode }) {
  return selectedCountryCode || detectedCountryCode;
}

export function getCountryCurrency(countries, countryCode) {
  const country = countries.find(({ code }) => code === countryCode);
  return country && country.currency_code;
}

export function getCountryName(countries, countryCode) {
  const country = countries.find(({ code }) => code === countryCode);
  return country && country.name;
}

export const blankCustomerDetails = (customerDetails) => ({
  firstName: customerDetails ? customerDetails.firstName : '',
  lastName: customerDetails ? customerDetails.lastName : '',
  email: '',
  address1: '',
  address2: '',
  address3: '',
  town: '',
  county: '',
  postCode: '',
  CompanionFirstName: '',
  CompanionLastName: '',
  CompanionEmail: '',
  whereHeard: customerDetails ? customerDetails.whereHeard : ''
});

export function getPrice(prices, selectedPriceID) {
  return selectedPriceID ? prices.find(price => price.id === selectedPriceID) : null;
}

export function getProduct(price) {
  return price ? price.variant.product : null;
}

export function getFormatNames(product) {
  return product.code.split('_').map(format => {
    switch (format) {
      case 'text':
        return 'print';

      case 'audio':
        return 'audio CD';

      case 'online':
        return 'online';
    }
  }).filter(Boolean);
}

export function getProductDescription(product) {
  if (product.code.indexOf('ovate_') === 0) {
    const formatNames = getFormatNames(product);
    return `Ovate course in the ${formatNames.join(' and ')} version${formatNames.length > 1 ? 's' : ''}`;
  } else if (product.code.indexOf('oo_') === 0) {
    const formatNames = getFormatNames(product);
    return `Ovate-Og course in the ${formatNames.join(' and ')} version${formatNames.length > 1 ? 's' : ''}`;
  } else if (product.code.indexOf('druid_') === 0) {
    const formatNames = getFormatNames(product);
    return `Druid course in the ${formatNames.join(' and ')} version${formatNames.length > 1 ? 's' : ''}`;
  } else if(product.name.includes("Hearth") || product.name.includes("hearth")) {
    return "The Druid Hearth Subscription"
  } else {
    let formatNames;

    switch (product.code) {
      case 'intro':
        return 'The Introductory Package';

      case 'touchstone_digital':
        return 'Touchstone Digital PDF Annual Subscription';

      case 'touchstone_printed':
        return 'Touchstone Printed Booklet Annual Subscription';

      case 'bardic_online_offer':
        return 'Bardic Online Offer';

      default:
        formatNames = getFormatNames(product);
        return `Join the Order and receive the training course in the ${formatNames.join(' and ')} version${formatNames.length > 1 ? 's' : ''}`;
    }
  }
}

export function getPriceFormatted(value, currency = 'GBP') {
  const formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency });
  return formatter.format(value);
}

export function getCustomerDetailsSet(customerDetails) {
  const requiredDetails = ['firstName', 'lastName', 'email', 'address1', 'town', 'postCode', 'whereHeard'];
  return customerDetails && requiredDetails.every(requiredDetail => customerDetails[requiredDetail]);
}

export function getAccessibleStages({ selectedPriceID, customerDetails }) {
  const stages = ['package'];

  if (selectedPriceID) {
    stages.push('details');
  } else {
    return stages;
  }

  if (getCustomerDetailsSet(customerDetails)) {
    stages.push('payment');
  }

  return stages;
}

export function checkOk(response) {
  if (!response.ok) throw new Error(`Got response ${response.status} for request to ${response.url}`);
  return response;
}

export async function fetchJSON(url, options = {}) {
  let host = ""
  if (process.env.config.environment == "development") {
    host = process.env.config.localServerUrl
  }
  const headers = new Headers(options.headers || {});
  headers.append('Content-Type', 'application/json');
  const response = await fetch(host + url, Object.assign({}, options, {
    headers
  })).then(checkOk);

  return response.json();
}

function setSentryUser(email) {
  if (email) {
    Sentry.setUser({ email });
  } else {
    Sentry.configureScope(scope => scope.setUser(null));
  }
}

export function loadOrInitialiseSession(name, defaults = {}) {
  const key = `session:${name}`;
  const existing = sessionStorage.getItem(key);
  const initialSession = existing ? JSON.parse(existing) : { ...defaults, key };
  const session = writable(initialSession);

  session.subscribe(newValue => {
    if (typeof newValue === 'object' && typeof newValue.customerDetails === 'object') {
      const email = newValue.customerDetails.email;
      setSentryUser(email);
    }

    sessionStorage.setItem(key, JSON.stringify(newValue));
  });

  return session;
}

export function clearSession({ key }) {
  try {
    sessionStorage.removeItem(key);
  } finally {
    setSentryUser(null);
  }
}

export async function getThankYouMessage(id) {
  let host = "";
  if (process.env.config.environment === "development") {
    host = process.env.config.localServerUrl;
  }
  const response = await fetch(`${host}/api/v2/orders/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  var data;
  if (response.ok) {
    data = await response.json();
    return data
  }

  return data
}

export async function convertImageToBase64(image) {
  if (!image) {
    return null;
  }
  try {
    const response = await fetch(image);
    const blob = await response.blob();
    const base64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
    return base64;
  } catch (error) {
    console.error('Error converting Blob URL to Base64:', error);
  }
}

export async function resizeBase64Image(base64, maxWidth, maxHeight) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = `data:image/jpeg;base64,${base64}`;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let newWidth, newHeight;

      if (img.width <= maxWidth && img.height <= maxHeight) {
        // If the image is smaller than the maximum dimensions, no need to resize
        newWidth = img.width;
        newHeight = img.height;
      } else {
        const widthRatio = img.width / maxWidth;
        const heightRatio = img.height / maxHeight;

        if (widthRatio > heightRatio) {
          newWidth = maxWidth;
          newHeight = img.height / widthRatio;
        } else {
          newHeight = maxHeight;
          newWidth = img.width / heightRatio;
        }
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      const resizedBase64 = canvas.toDataURL('image/jpeg');
      resolve(resizedBase64);
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
}


export async function getServerState(language_name) {
  let host = "";
  if (process.env.config.environment === "development") {
    host = process.env.config.localServerUrl;
  }
  const response = await fetch(`${host}/api/v2/language/${language_name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  var data;
  if (response.ok) {
    data = await response.json();
    return data
  }

  return data
}
